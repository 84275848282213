import React, { useState, useEffect } from 'react';
import { ArrowRight, Menu, X, Phone } from 'lucide-react';
import './SeoHero.css';
import usePhoneNumber from '../../hooks/usePhoneNumber';

const SeoHero = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { phoneNumber, isLoading } = usePhoneNumber();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToSection = (id) => {
    const section = document.getElementById(`seo-${id}`);
    if (section) {
      const offsetTop = section.offsetTop;
      window.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      });
      setIsMobileMenuOpen(false);
    }
  };

  const handleCall = () => {
    window.location.href = `tel:${phoneNumber.replace(/\s+/g, '')}`;
  };

  return (
    <div className="seo-hero">
      {/* Background Elements */}
      <div className="seo-hero-bg">
        <video 
          autoPlay 
          muted 
          loop 
          playsInline
          className="seo-hero-bg-video"
        >
          <source src="/assets/ai-seo-video.mp4" type="video/mp4" />
        </video>
        <div className="seo-hero-bg-gradient" />
      </div>

      {/* Header */}
      <header className={`seo-header ${isScrolled ? 'scrolled' : ''}`}>
        <div className="seo-header-container">
          <div className="seo-logo">
            <img src="./logo.png" alt="HEALM Logo" />
          </div>

          {/* Mobile Menu Button */}
          <button 
            className="mobile-menu-button"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            aria-label="Toggle mobile menu"
          >
            {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>

          <nav className={`seo-nav ${isMobileMenuOpen ? 'active' : ''}`}>
            <div className="seo-nav-links">
              <a onClick={() => scrollToSection('meet')}>Meet</a>
              <a onClick={() => scrollToSection('features')}>Features</a>
              <a onClick={() => scrollToSection('benefits')}>Benefits</a>
              <a onClick={() => scrollToSection('process')}>Process</a>
              <a onClick={() => scrollToSection('pricing')}>Pricing</a>
            </div>
            <button 
              className="seo-nav-cta" 
              onClick={handleCall}
            >
              <Phone size={16} className="phone-icon" />
              {isLoading ? 'Loading...' : phoneNumber}
            </button>
          </nav>
        </div>
      </header>

      {/* Main Content */}
      <div className="seo-hero-content">
        <div className="hero-main">
          <h1 className="seo-hero-title">
            AI-Driven Offsite SEO Innovations
          </h1>
          <p className="seo-hero-subtitle">
            Healm's pioneering AI-powered SEO tactics unlock enhanced Google rankings 
            and propel business expansion
          </p>
          <div className="hero-cta">
            <button 
              className="hero-cta-button"
              onClick={() => scrollToSection('pricing')}
            >
              See Plans
              <ArrowRight size={20} />
            </button>
          </div>
        </div>

        {/* Analysis Banner */}
        <div className="analysis-banner">
          <div className="analysis-content">
            <div className="analysis-left">
              <div className="analysis-info">
                <div className="beacon-container">
                  <div className="beacon" />
                </div>
                <div className="analysis-text">
                  <span className="analysis-label">Ready to Scale?</span>
                  <span className="analysis-title">
                    Start Your <span className="analysis-highlight">Free Analysis</span> Today
                  </span>
                </div>
              </div>
              <button 
                className="analysis-cta"
                onClick={handleCall}
              >
                <Phone size={16} className="phone-icon" />
                <span>{isLoading ? 'Loading...' : phoneNumber}</span>
                <ArrowRight size={16} />
              </button>
            </div>
            
            <div className="analysis-stats">
              <div className="stat-item">
                <div className="stat-value">400+</div>
                <div className="stat-label">Blog Posts Monthly</div>
              </div>
              <div className="stat-item">
                <div className="stat-value">400+</div>
                <div className="stat-label">Quality Backlinks</div>
              </div>
              <div className="stat-item">
                <div className="stat-value">65%</div>
                <div className="stat-label">Visibility Increase</div>
              </div>
              <div className="stat-item">
                <div className="stat-value">90%</div>
                <div className="stat-label">Client Growth</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeoHero;