import LoginPage from './pages/auth/loginPage';
import { Routes, Route, Navigate } from "react-router-dom";
import RegisterPage from './pages/auth/registerPage';
import ForgotpassPage from './pages/auth/forgotPage';
import {useNavigate} from 'react-router-dom';
import React, { lazy, Suspense, useState } from 'react';
import SignupSpecial from './pages/signup-special/page.js';
import TwentyTwentyFourPDFPage from './pages/2024PDF.js';
import VirtualTourLandingPage from './pages/VirtualToursLanding/page.js';
import SeoLandingPage from './pages/AI-SEO/SeoLandingPage.jsx';

const AvatarsPage = lazy(() => import('./pages/avatarsPage'));
const PlanetsPage = lazy(() => import('./pages/planetsPage'));
const AboutPage = lazy(() => import('./pages/aboutPage'));
const SupportPage = lazy(() => import('./pages/supportPage'));

const PartnersPage = lazy(() => import('./pages/partnersPage'));
const WalletPage = lazy(() => import('./pages/walletPage'));
const HomePage = lazy(() => import('./pages/homePage'));

const TeamPage = lazy(() => import('./pages/teamPage'));
const PolicyPage = lazy(() => import('./pages/policyPage'));
const AccessVerifyPage = lazy(() => import('./pages/backend/access-codePage'));
const SettingsPage = lazy(() => import('./pages/backend/settingsPage'));

const EmailSubscribe = lazy(() => import('./components/home/child-formSubmit'));
const HomeAccodionFAQ = lazy(() => import('./components/home/child-accordionFaq'));
const AuthTest = lazy(() => import('./components/user/authtest'));
const SliderComponent = lazy(() => import('./components/home/sliderComponent'));

const FavoritesPage = lazy(() => import('./pages/backend/FavoritesPage'));
const EarlyAccessPage = lazy(() => import('./pages/backend/EarlyAccessPage'));
const PlanetPage = lazy(() => import('./pages/backend/planetPage'));
const ReadyPlayerMePage = lazy(() => import('./pages/backend/readyPlayerMePage'));

const TermsPage = lazy(() => import('./pages/termsPage'));
const AirdropPage = lazy(() => import('./pages/airdropPage'));
const CreatePlanetPage = lazy(() => import('./pages/CreatePlanetPage'));
const PlanetEarthPage = lazy(() => import('./pages/PlanetEarthPage'));
const LandSalePage = lazy(() => import('./pages/LandSalePage'));

const ProtectedRoutes = lazy(() => import('./hooks/useAuth'));

const SessionTest = lazy(() => import('./components/user/sessionTest'));
const PDFPage = lazy(() => import('./pages/pdfDownload'));
const TestPDFPage = lazy(() => import('./pages/earthPDF'));

const AvatarView = lazy(() => import('./components/backend/avatarGLB'));
const DropdownCont = lazy(() => import('./components/backend/dropdown'));
const Modal = lazy(() => import('react-modal'));
const AdminIndexPage = lazy(() => import('./pages/admin/adminIndex'));

const AvatarMainPage = lazy(() => import('./pages/backend/avatar/AvatarMain'));
const AvatarCreatePage = lazy(() => import('./pages/backend/avatar/AvatarCreate'));
const AvatarImportPage = lazy(() => import('./pages/backend/avatar/AvatarImport'));
const NotificationsPage = lazy(() => import('./pages/backend/notificationsPage'));

const AvatarImport2Page = lazy(() => import('./pages/backend/avatar/AvatarImport2'));
const AvatarImportSelectPage = lazy(() => import('./pages/backend/avatar/AvatarImportSelect'));
const SignUpForm = lazy(() => import('./components/user/registerForm'));
const Encrypt = lazy(() => import('./utilities/encrypt'));

const ETHEarlyAccess = lazy(() => import('./components/backend/earlyaccess/ETH'));
const TestPage = lazy(() => import('./pages/testPage'));
const EarthPDFPage = lazy(() => import('./pages/earthPDF'));
const PartnerPDFPage = lazy(() => import('./pages/partnerPDF'));

const TestURLRedirect = lazy(() => import('./pages/testURLredirect'));
const EnvTest = lazy(() => import('./pages/envtest'));
const V2SettingsPage = lazy(() => import('./pages/backend/V2settingsPage'));
const V2AccessVerifyPage = lazy(() => import('./pages/backend/V2access-codePage'));

const V2AvatarSelectPage = lazy(() => import('./pages/backend/avatar/V2AvatarSelect'));
const V2EarlyAccessPage = lazy(() => import('./pages/backend/V2EarlyAccessPage'));
const TopBarStickBar = lazy(() => import('./utilities/topStickyNav'));
const ClearSession = lazy(() => import('./pages/ClearSession'));

const ForgotPasswordPage = lazy(() => import('./pages/ForgotPassword'));
const NotFoundPage = lazy(() => import('./pages/404.js'));
const CodeVerifiedProtectedRoutes = lazy(() => import('./hooks/useVerification'));
const DeviceTestPage = lazy(() => import('./pages/deviceTest'));

const IPTestPage = lazy(() => import('./pages/ipTest'));
const TwoEarthPDFPage = lazy(() => import('./pages/2earthPDF'));
const TwoPartnerPDFPage = lazy(() => import('./pages/2partnerPDF'));
const PreSaleAccessPage = lazy(() => import('./pages/backend/PreSaleAccessPage'));

const DashboardWalletPage = lazy(() => import('./pages/backend/wallet/dashboardWalletPage'));
const AdPricingPDFPage = lazy(() => import('./pages/pdf-ad-pricing'));
const AdPDFPage = lazy(() => import('./pages/pdf-ad'));
const RedirectDashboard = lazy(() => import('./pages/redirectDashboard'));

const UKPartnerPage = lazy(() => import('./pages/ukPartnerPage'));
const NewAdPDFPage = lazy(() => import('./pages/adPDF'));
const ComingSoonPage = lazy(() => import('./pages/comingsoon/comingsoon'));
const HomeApp = lazy(() => import('./pages/HomePage/main'));


function App() {

  const { EncryptStorage } = require('storage-encryption')
  var SECRET_KEY = process.env.REACT_APP_SECRET_KEY
  const encryptStorage = new EncryptStorage(SECRET_KEY, 'localStorage');
  const decryptedLogin = encryptStorage.decrypt('session_key_')
  
  let navigate = useNavigate();


  const [hasSession, setHasSession] = useState(false)

  function RemoveTopBar(){
    setHasSession(true);
  }

  // const navigate = useNavigate();


 

  return (
    <>


    {/* BLUE STICK TOPBAR */}
    {/* {hasSession === false ? <TopBarStickBar/> : "" } */}
    {/* BLUE STICK TOPBAR */}


    <div className="App" style={{position: 'relative'}}>
    <Routes>
       <Route path="ad" element={<AdPDFPage/>} />
        {/* <Route path="ad-pricing" element={<AdPricingPDFPage/>} /> */}
    </Routes>

    <Suspense fallback={<div></div>}>

      <Routes>
      <Route path="ad-pricing" element={<AdPricingPDFPage/>} />

          <Route path="/" element={<HomeApp/>} />
          <Route path="/tours" element={<VirtualTourLandingPage/>} />
          <Route path="/AI-SEO" element={<SeoLandingPage/>} />

          {/* <Route path="/" element={<ComingSoonPage/>} /> */}

          {/* <Route path="/" element={<HomePage/>} /> */}
          {/* <Route path="/comingsoon" element={<ComingSoonPage/>} /> */}

          {/* <Route path="*" element={<NotFoundPage/>} /> */}
          <Route path="*" element={<HomeApp/>} />

          {/* USER */}
          {/* <Route path="enter" element={<LoginPage hasSession={hasSession} />} /> */}
          <Route path="register" element={<RegisterPage/>} />
          {/* <Route path="forgot" element={<ForgotpassPage/>} /> */}

          <Route path="presentation" element={<TwentyTwentyFourPDFPage/>} />

          {/* <Route path="uk-partner" element={<UKPartnerPage/>} /> */}
          <Route path="adpartner" element={<UKPartnerPage/>} />
          <Route path="adPDF" element={<NewAdPDFPage/>} />
          <Route path="signup-special" element={<SignupSpecial/>} />


          {/* LEARN MORE  */}
          {/* <Route path="avatars" element={<AvatarsPage/>} />
          <Route path="planets" element={<PlanetsPage/>} />
          <Route path="about" element={<AboutPage/>} />
          <Route path="team" element={<TeamPage/>} />

          <Route path="support" element={<SupportPage/>} />

          <Route path="create-planet" element={<CreatePlanetPage/>} />
          <Route path="planet-earth" element={<PlanetEarthPage/>} />
          <Route path="land-sale" element={<LandSalePage/>} /> */}


          {/* CRYPTOCURRENCY  */}
          {/* <Route path="partners" element={<PartnersPage/>} />

          <Route path="chest" element={<PartnersPage/>} />
          <Route path="wallet" element={<WalletPage/>} />
           */}
          {/* LEGAL PAGES */}
          <Route path="policy" element={<PolicyPage/>} />
          <Route path="terms" element={<TermsPage/>} />
          {/* <Route path="airdropinfo" element={<AirdropPage/>} /> */}


          {/* <Route path="earth-presentation" element={<PDFPage/>} /> */}
          <Route path="earth-presentation" element={<EarthPDFPage/>} />
          <Route path="partner-pdf" element={<PartnerPDFPage/>} />

          <Route path="2earth-presentation" element={<TwoEarthPDFPage/>} />
          <Route path="2partner-pdf" element={<TwoPartnerPDFPage/>} />

          {/* {/* <Route path="ad" element={<AdPDFPage/>} /> */}
          <Route path="ad-pricing" element={<AdPricingPDFPage/>} /> 


          {/* <Route path="earth-presentation" element={<RedirectURL/>}/> */}



          {/* <Route path="verify" element={<AccessVerifyPage/>} /> */}

          {/* PROTECTED  */}
          <Route element={<ProtectedRoutes RemoveTopBar={RemoveTopBar}/>}>
            <Route path="verify" element={<V2AccessVerifyPage/>} />
                <Route path="account/settings" element={<V2SettingsPage/>} />
                <Route path="account/planets" element={<PlanetPage/>} />
                <Route path="account/favorites" element={<FavoritesPage/>} />
                <Route path="account/wallet" element={<SettingsPage/>} />
                <Route path="account/marketplace" element={<SettingsPage/>} />
                <Route path="account/exchange" element={<SettingsPage/>} />
                <Route path="account/earlyaccess" element={<V2EarlyAccessPage/>} />

                {/* <Route path="account/presaleaccess" element={<PreSaleAccessPage/>} /> */}
                <Route path="account/presaleaccess" element={<RedirectDashboard/>} />


                <Route path="account/notifications" element={<NotificationsPage/>} />
                <Route path="account/avatar" element={<AvatarMainPage/>} />
                <Route path="account/avatar/select" element={<V2AvatarSelectPage/>} />
                <Route path="account/avatar/rpm" element={<ReadyPlayerMePage/>} />
                <Route path="admin/index" element={<AdminIndexPage/>} />

                <Route path="account/wallet/dashboard" element={<DashboardWalletPage/>} />



            <Route path="account/feedback" element={<SettingsPage/>} />
            <Route path="account/help" element={<SettingsPage/>} />

          

          </Route>



        {/* AVATARS  */}
              {/* <Route path="account/avatar/main" element={<AvatarMainPage/>} />
            <Route path="account/avatar/create" element={<AvatarCreatePage/>} />
            <Route path="account/avatar/import" element={<AvatarImportPage/>} />
            <Route path="account/avatar/import-2" element={<AvatarImport2Page/>} /> */}
            {/* <Route path="account/avatar/select" element={<AvatarImportSelectPage/>} /> */}
          {/* TEMP.....DELETE LATER */}
          {/* <Route path="navtest" element={<TestPage/>} />

          <Route path="testurl_redirect" element={<TestURLRedirect/>} />

          <Route path="envtest" element={<EnvTest/>} /> */}

          <Route path="clearsession" element={<ClearSession/>} />

          <Route path="device-test" element={<DeviceTestPage/>} />
          <Route path="ip-test" element={<IPTestPage/>} />

          <Route 
            path="ref/*" 
            element={<TestURLRedirect/>}
            
            />



          <Route path="forgot-password/:id/:email" element={<ForgotPasswordPage/>} />



          {/* <Route path="emailtest" element={<EmailSubscribe/>} />
          <Route path="accordion" element={<HomeAccodionFAQ/>} />
          <Route path="auth" element={<AuthTest/>} />
          <Route path="slidertest" element={<SliderComponent/>} />
          
          <Route path="formtest" element={<SignUpForm/>} />

          <Route path="seshtest" element={<SessionTest/>} />
          <Route path="glb" element={<AvatarView/>} />
          <Route path="dropdown" element={<DropdownCont/>} />

          <Route path="encrypt" element={<Encrypt/>} /> */}




      </Routes>
      </Suspense>


    </div>
    </>
  );
}

export default App;
