import { useState, useEffect } from 'react';

const PHONE_NUMBERS = {
  US: '+1 (424) 248-7873',
  AU: '+61 02 7228 3628',
  DEFAULT: '+1 (424) 248-7873'
};

const IPSTACK_KEY = 'b5ac421741b7f25f69799d207c50105a';

const usePhoneNumber = () => {
  const [phoneNumber, setPhoneNumber] = useState(PHONE_NUMBERS.DEFAULT);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Try to get cached country first
    const cachedCountry = localStorage.getItem('user_country');
    if (cachedCountry) {
      setPhoneNumber(PHONE_NUMBERS[cachedCountry] || PHONE_NUMBERS.DEFAULT);
      setIsLoading(false);
      return;
    }

    const controller = new AbortController();

    const fetchLocation = async () => {
      try {
        setError(null);
        
        const response = await fetch(`https://api.ipstack.com/check?access_key=${IPSTACK_KEY}`, {
          signal: controller.signal,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        
        if (data.country_code) {
          // Cache the result
          localStorage.setItem('user_country', data.country_code);
          const newNumber = PHONE_NUMBERS[data.country_code] || PHONE_NUMBERS.DEFAULT;
          setPhoneNumber(newNumber);
        } else {
          throw new Error('No country code in response');
        }
      } catch (error) {
        if (error.name === 'AbortError') return;
        
        console.error('Error fetching location:', error);
        setError(error.message);
        // Fallback to default number on error
        setPhoneNumber(PHONE_NUMBERS.DEFAULT);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLocation();

    return () => {
      controller.abort();
    };
  }, []);

  return { phoneNumber, isLoading, error };
};

export default usePhoneNumber;