import React from 'react';
import './SeoFeatures.css';
import { 
  PenTool, 
  Link, 
  BarChart3, 
  Rocket 
} from 'lucide-react';

const SeoFeatures = () => {
  const features = [
    {
      icon: <PenTool size={32} />,
      title: 'Automated Content Creation',
      description: "Healm's AI platform, Healm Optima, effortlessly generates up to 400 compelling blog posts monthly for each target keyword, maintaining a consistent stream of fresh, relevant, and captivating content to enhance visibility and engagement.",
      color: "#E89B5D"
    },
    {
      icon: <Link size={32} />,
      title: 'Automated Backlink Creation',
      description: "In addition to blog posts, Healm's AI, Healm Optima, can generate up to 400 high-quality backlinks per month for each target keyword, enhancing your website's authority and improving its visibility in search engine rankings.",
      color: "#D17E3B"
    },
    {
      icon: <Rocket size={32} />,
      title: 'Scalable and Efficient',
      description: "Healm's AI-driven approach allows businesses to scale their content and link-building efforts significantly, without the need for additional manual effort or resources.",
      color: "#E89B5D"
    },
    {
      icon: <BarChart3 size={32} />,
      title: 'Improved SEO Performance',
      description: "By harnessing the combined power of AI-generated content and backlinks, Healm Optima enables businesses to achieve higher search engine rankings, increased organic traffic, and significantly enhanced overall SEO performance.",
      color: "#D17E3B"
    }
  ];

  return (
    <section className="seo-features">
      <div className="seo-features-content">
        <h2 className="seo-features-title">
          Healm Optima: AI-Driven Excellence
        </h2>

        <div className="seo-features-grid">
          {features.map((feature, index) => (
            <div key={index} className="seo-feature-card">
              <div 
                className="seo-feature-icon"
                style={{ 
                  background: `linear-gradient(135deg, ${feature.color}22 0%, ${feature.color}11 100%)`
                }}
              >
                <div className="seo-feature-icon-inner" style={{ color: feature.color }}>
                  {feature.icon}
                </div>
              </div>
              <div className="seo-feature-content">
                <h3 className="seo-feature-title">{feature.title}</h3>
                <p className="seo-feature-description">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      
      {/* Background Elements */}
      <div className="seo-features-bg-glow"></div>
      <div className="seo-features-bg-grid"></div>
    </section>
  );
};

export default SeoFeatures;