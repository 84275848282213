import React from 'react';
import { FileText, Link2, BarChart3 } from 'lucide-react';
import './SeoLimitations.css';

const SeoLimitations = () => {
  const limitations = [
    {
      icon: <FileText size={32} />,
      title: 'Laborious Content Creation',
      description: 'Manually crafting unique, high-quality content for each web page is labor-intensive and does not scale well with growing business needs.'
    },
    {
      icon: <Link2 size={32} />,
      title: 'Tedious Link Building',
      description: 'Manually outreaching and securing backlinks from other websites is a time-consuming process with limited control over the quality and relevance of the links.'
    },
    {
      icon: <BarChart3 size={32} />,
      title: 'Inefficient Campaign Management',
      description: 'Manually tracking, analysing, and optimising SEO campaigns across multiple channels and platforms is complex and prone to human error.'
    }
  ];

  return (
    <section className="seo-limitations">
      <div className="seo-limitations-content">
        <h2 className="seo-limitations-title">
          The Limitations of Traditional SEO
        </h2>
        <p className="seo-limitations-subtitle">
          Why conventional SEO approaches fall short in today's digital landscape
        </p>

        <div className="seo-limitations-grid">
          {limitations.map((item, index) => (
            <div key={index} className="limitation-card">
              <div className="limitation-icon-wrapper">
                <div className="limitation-icon">
                  {item.icon}
                </div>
              </div>
              <h3 className="limitation-title">{item.title}</h3>
              <p className="limitation-description">{item.description}</p>
            </div>
          ))}
        </div>

        <div className="seo-limitations-footer">
          <p>
            As the search landscape shifts and business needs expand, conventional SEO tactics prove
            inadequate. Automation and scalability are essential for attaining lasting SEO success.
          </p>
        </div>
      </div>
      
      {/* Background Elements */}
      <div className="limitations-glow-1"></div>
      <div className="limitations-glow-2"></div>
      <div className="limitations-grid-bg"></div>
    </section>
  );
};

export default SeoLimitations;