import React from 'react';
import './SeoProcess.css';
import { 
  Database, 
  Cog, 
  PenTool,
  BarChart2
} from 'lucide-react';

const SeoProcess = () => {
  const steps = [
    {
      icon: <Database size={32} />,
      title: "Data Gathering",
      description: "Our AI-powered tools gather and analyse extensive search data, including your domain score, available backlinks, competitor insights, and user search behaviour. This enables us to identify high-value keywords and target the right phrases to drive optimal SEO performance and visibility."
    },
    {
      icon: <Cog size={32} />,
      title: "Off-Site Optimisation",
      description: "Our AI algorithms pinpoint opportunities to optimise off-site content, build high-quality backlinks, and leverage external factors, boosting your search engine visibility and driving higher rankings."
    },
    {
      icon: <PenTool size={32} />,
      title: "Automated Content Generation",
      description: "Through our partners, we publish high-quality, SEO-optimised content powered by natural language processing and machine learning, ensuring it resonates with both search engines and your target audience."
    },
    {
      icon: <BarChart2 size={32} />,
      title: "Reporting & Insights",
      description: "Every 30 days, our AI delivers detailed reports on keyword performance, showcasing improvements in Google listings, domain authority, and overall search engine visibility, providing clear insights into the impact of your SEO strategy."
    }
  ];

  return (
    <section className="healm-process-section">
      <div className="healm-process-wrapper">
        <h2 className="healm-process-header">
          Step-by-Step AI-Driven SEO Process
        </h2>

        <div className="healm-process-container">
          {steps.map((step, index) => (
            <div key={index} className="healm-process-item">
              <div className="healm-process-box">
                <div className="healm-process-icon-wrapper">
                  {step.icon}
                </div>
                <h3 className="healm-process-item-title">{step.title}</h3>
                <p className="healm-process-item-text">{step.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Visual elements */}
      <div className="healm-process-gradient-top"></div>
      <div className="healm-process-gradient-bottom"></div>
      <div className="healm-process-grid"></div>
    </section>
  );
};

export default SeoProcess;