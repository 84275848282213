import React, { useState } from 'react';
import { X, Phone, Loader } from 'lucide-react';
import usePhoneNumber from '../../hooks/usePhoneNumber';
import './ContactFormModal.css';

const ContactFormModal = ({ isOpen, onClose }) => {
  const { phoneNumber, isLoading: phoneLoading } = usePhoneNumber();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null); // 'success' | 'error' | null

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);

    const formData = new FormData(e.target);
    
    try {
      const response = await fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        body: formData
      });

      const data = await response.json();

      if (data.success) {
        setSubmitStatus('success');
        e.target.reset();
        // Optional: close modal after success
        setTimeout(() => {
          onClose();
          setSubmitStatus(null);
        }, 3000);
      } else {
        setSubmitStatus('error');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCall = () => {
    window.location.href = `tel:${phoneNumber.replace(/\s+/g, '')}`;
  };

  if (!isOpen) return null;

  return (
    <div className="healm-modal-overlay">
      <div className="healm-contact-modal">
        <button 
          className="healm-modal-close"
          onClick={onClose}
          aria-label="Close modal"
        >
          <X size={24} />
        </button>

        <div className="healm-modal-header">
          <h2 className="healm-modal-title">Contact Sales Team</h2>
          <button 
            className="healm-phone-button"
            onClick={handleCall}
            disabled={phoneLoading}
          >
            <Phone size={20} />
            {phoneLoading ? 'Loading...' : `Or call us at ${phoneNumber}`}
          </button>
        </div>

        {submitStatus === 'success' ? (
          <div className="success-message">
            <h3>Thank you for your submission!</h3>
            <p>We'll be in touch with you shortly.</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="healm-contact-form">
            {/* Hidden Web3Forms Access Key */}
            <input 
              type="hidden" 
              name="access_key" 
              value="ffba47d1-d853-4bd6-bf19-146e98e2e16f"
            />

            {/* Add multiple recipients */}

<input type="hidden" name="ccemail" value="mark@healm.com, jess@healm.com, tom@healm.com"/>

            {/* Optional: capture URL of page */}
            <input 
              type="hidden" 
              name="from_page" 
              value={window.location.href}
            />

            <div className="form-group">
              <label htmlFor="name">Full Name *</label>
              <input
                type="text"
                id="name"
                name="name"
                required
                placeholder="Enter your full name"
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email Address *</label>
              <input
                type="email"
                id="email"
                name="email"
                required
                placeholder="Enter your email address"
              />
            </div>

            <div className="form-group">
              <label htmlFor="phone">Phone Number *</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                required
                placeholder="Enter your phone number"
              />
            </div>

            <div className="form-group">
              <label htmlFor="business">Business Name *</label>
              <input
                type="text"
                id="business"
                name="business"
                required
                placeholder="Enter your business name"
              />
            </div>

            <div className="form-group">
              <label htmlFor="bestTime">Best Time to Call *</label>
              <select
                id="bestTime"
                name="bestTime"
                required
              >
                <option value="">Select a time</option>
                <option value="morning">Morning (9AM - 12PM)</option>
                <option value="afternoon">Afternoon (12PM - 5PM)</option>
                <option value="evening">Evening (5PM - 8PM)</option>
              </select>
            </div>

            {submitStatus === 'error' && (
              <div className="error-message">
                Something went wrong. Please try again or call us directly.
              </div>
            )}

            <button 
              type="submit" 
              className="submit-button"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <span className="loading-wrapper">
                  <Loader size={20} className="spinner" />
                  Submitting...
                </span>
              ) : 'Submit Request'}
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default ContactFormModal;