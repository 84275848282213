import React, { useState } from 'react';
import './SeoPricing.css';
import { Check, Phone } from 'lucide-react';
import ContactFormModal from '../ContactFormModal/ContactFormModal';

const SeoPricing = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);


  const plans = [
    {
      name: "Starter",
      highlight: "Most Popular",
      pricingText: "Call for Pricing",
      description: "Perfect for small businesses ready to test our proven SEO system with one keyword and see results.",
      features: [
        "400 AI-Generated Blog Posts Monthly",
        "400 High-Quality Backlinks",
        "1 Target Keyword Optimization",
        "Monthly Performance Reports",
        "AI Content Optimization",
        "24/7 Support Access"
      ]
    },
    {
      name: "Growth",
      pricingText: "Call for Pricing",
      description: "Ideal for growing businesses targeting multiple keywords",
      features: [
        "800 AI-Generated Blog Posts Monthly",
        "800 High-Quality Backlinks",
        "2 Target Keyword Optimization",
        "Priority Support",
        "Advanced Analytics Dashboard",
        "Custom Content Strategy",
        "Competitor Analysis"
      ]
    },
    {
      name: "Enterprise",
      pricingText: "Call for Pricing",
      description: "Custom solution for large-scale SEO campaigns",
      features: [
        "Unlimited AI-Generated Content",
        "Custom Backlink Strategy",
        "Multiple Keyword Optimization",
        "Dedicated Account Manager",
        "Custom Reporting",
        "API Access",
        "White-label Options"
      ]
    }
  ];

  return (
    <section className="seo-pricing">
      <div className="seo-pricing-content">
        <h2 className="seo-pricing-title">
          Choose Your Plan
        </h2>
        <p className="seo-pricing-subtitle">
          Select the perfect solution for your business needs
        </p>

        <div className="seo-pricing-grid">
          {plans.map((plan, index) => (
            <div key={index} className={`seo-plan-card ${plan.highlight ? 'highlighted' : ''}`}>
              {plan.highlight && (
                <div className="plan-highlight-tag">
                  {plan.highlight}
                </div>
              )}
              
              <div className="plan-header">
                <h3 className="plan-name">{plan.name}</h3>
                <p className="plan-description">{plan.description}</p>
                <div className="plan-pricing">
                  <Phone size={20} className="pricing-icon" />
                  <span className="pricing-text">{plan.pricingText}</span>
                </div>
              </div>

              <div className="plan-features">
                {plan.features.map((feature, idx) => (
                  <div key={idx} className="feature-item">
                    <Check size={20} className="feature-icon" />
                    <span>{feature}</span>
                  </div>
                ))}
              </div>

              <button 
                className="plan-cta-button"
                onClick={() => setIsContactModalOpen(true)}
              >
                Contact Sales
              </button>
            </div>
          ))}
        </div>

        <div className="seo-pricing-footer">
          <p className="pricing-disclaimer">
            Flexible 3-month commitment with guaranteed results. Cancel anytime after 90 days.
          </p>
        </div>
      </div>
<ContactFormModal 
  isOpen={isContactModalOpen} 
  onClose={() => setIsContactModalOpen(false)} 
/>
      {/* Background Elements */}
      <div className="pricing-glow-1"></div>
      <div className="pricing-glow-2"></div>
      <div className="pricing-grid"></div>
    </section>
  );
};

export default SeoPricing;