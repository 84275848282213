import React from 'react';
import { Helmet } from 'react-helmet';
import './SeoLandingPage.css';

// Component imports
import SeoHero from './components/SeoHero/SeoHero';
import SeoMeet from './components/SeoMeet/SeoMeet';
import SeoLimitations from './components/SeoLimitations/SeoLimitations';
import SeoFeatures from './components/SeoFeatures/SeoFeatures';
import SeoBenefits from './components/SeoBenefits/SeoBenefits';
import SeoImpact from './components/SeoImpact/SeoImpact';
import SeoProcess from './components/SeoProcess/SeoProcess';
import SeoJourney from './components/SeoJourney/SeoJourney';
import SeoPricing from './components/SeoPricing/SeoPricing';
import SeoFooter from './components/SeoFooter/SeoFooter';

const SeoLandingPage = () => {
  // SEO metadata
  const pageTitle = "AI-Driven SEO Services | HEALM";
  const pageDescription = "Transform your online presence with HEALM's AI-powered SEO services. Expert offsite optimization, content strategy, and proven results.";
  
  const sections = [
    { 
      id: 'hero', 
      Component: SeoHero,
      ariaLabel: 'Introduction'
    },
    { 
      id: 'meet', 
      Component: SeoMeet,
      ariaLabel: 'About Our Services'
    },
    { 
      id: 'limitations', 
      Component: SeoLimitations,
      ariaLabel: 'Common SEO Challenges'
    },
    { 
      id: 'features', 
      Component: SeoFeatures,
      ariaLabel: 'Service Features'
    },
    { 
      id: 'benefits', 
      Component: SeoBenefits,
      ariaLabel: 'Key Benefits'
    },
    { 
      id: 'impact', 
      Component: SeoImpact,
      ariaLabel: 'Business Impact'
    },
    { 
      id: 'process', 
      Component: SeoProcess,
      ariaLabel: 'Our Process'
    },
    { 
      id: 'journey', 
      Component: SeoJourney,
      ariaLabel: 'Client Journey'
    },
    { 
      id: 'pricing', 
      Component: SeoPricing,
      ariaLabel: 'Service Plans and Pricing'
    },
    { 
      id: 'footer', 
      Component: SeoFooter,
      ariaLabel: 'Additional Information'
    }
  ];

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        
        {/* Open Graph tags */}
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        
        {/* Additional SEO meta tags */}
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={window.location.href} />
        
        {/* Schema.org markup */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": pageTitle,
            "description": pageDescription,
            "provider": {
              "@type": "Organization",
              "name": "HEALM",
              "url": window.location.origin
            }
          })}
        </script>
      </Helmet>

      <main className="seo-landing-page">
        <h1 className="sr-only">{pageTitle}</h1>
        {sections.map(({ id, Component, ariaLabel }) => (
          <section 
            key={id} 
            id={`seo-${id}`} 
            className="seo-section"
            aria-label={ariaLabel}
          >
            <Component />
          </section>
        ))}
      </main>
    </>
  );
};

export default SeoLandingPage;