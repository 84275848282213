import React from 'react';
import { FaLinkedinIn, FaInstagram, FaFacebookF } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import './SeoFooter.css';

const SeoFooter = () => {
  return (
    <footer className="seo-footer">
      <div className="seo-footer-content">
        <div className="seo-footer-main">
          <div className="seo-footer-brand">
            <div className="seo-footer-logos">
              <img src="./logo.png" alt="Healm Logo" className="seo-footer-logo"/>
              <div className="seo-footer-partner">
                <img src="./partners/google-partner-2.png" alt="Google Partner"/>
              </div>
            </div>
            <p className="seo-footer-description">
              Transform your online presence with Healm's AI-powered SEO solutions. Our cutting-edge technology and proven 
              strategies help businesses achieve higher rankings, increased visibility, and sustainable growth in search results.
            </p>
          </div>

          <div className="seo-footer-links">
            <div className="seo-footer-column">
              <h4>Services</h4>
              <ul>
                <li><Link to="/">AI Content Generation</Link></li>
                <li><Link to="/">Backlink Optimization</Link></li>
                <li><Link to="/">SEO Analytics</Link></li>
                <li><Link to="/">Keyword Research</Link></li>
              </ul>
            </div>
            <div className="seo-footer-column">
              <h4>Company</h4>
              <ul>
                <li><Link to="/">About Us</Link></li>
                <li><Link to="/">Case Studies</Link></li>
                <li><Link to="/">Testimonials</Link></li>
                <li><Link to="/">Contact</Link></li>
              </ul>
            </div>
          </div>
        </div>

        <div className="seo-footer-social">
          <div className="seo-social-icons">
            <a href="https://www.linkedin.com/company/healm-llc" target="_blank" rel="noopener noreferrer">          
              <FaLinkedinIn />
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">          
              <FaInstagram />
            </a>
            <a href="https://twitter.com/healmxr" target="_blank" rel="noopener noreferrer">          
              <FaXTwitter />
            </a>
            <a href="https://www.facebook.com/healmreality" target="_blank" rel="noopener noreferrer">          
              <FaFacebookF />
            </a>
          </div>
        </div>

        <div className="seo-footer-bottom">
          <div className="seo-footer-policies">
            <Link to="/policy">Cookie Policy</Link>
            <span className="separator">|</span>
            <Link to="/policy">Privacy Policy</Link>
          </div>
          <div className="seo-footer-copyright">
            © Healm Ltd. 2024
          </div>
        </div>
      </div>

      {/* Background Elements */}
      <div className="footer-glow-1"></div>
      <div className="footer-glow-2"></div>
      <div className="footer-grid"></div>
    </footer>
  );
};

export default SeoFooter;