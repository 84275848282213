import React from 'react';
import { ClipboardCheck, Search, Layers, BarChart3Icon, Award } from 'lucide-react';
import './SeoJourney.css';

const SeoJourney = () => {
  const steps = [
    {
      icon: <ClipboardCheck size={28} />,
      number: "01",
      title: "Sign Up & Keyword Selection"
    },
    {
      icon: <Search size={28} />,
      number: "02",
      title: "SEO Assessment & Analysis"
    },
    {
      icon: <Layers size={28} />,
      number: "03",
      title: "Content Generation & Optimization"
    },
    {
      icon: <BarChart3Icon size={28} />,
      number: "04",
      title: "Performance Reports & Validation"
    },
    {
      icon: <Award size={28} />,
      number: "05",
      title: "Review & Results Verification"
    }
  ];

  return (
    <section className="seo-journey">
      <div className="seo-journey-content">
        <h2 className="seo-journey-title">
          Your Journey with Healm Optima
        </h2>

        <div className="seo-journey-timeline">
          {/* Main timeline line */}
          <div className="timeline-line" />

          {/* Timeline steps */}
          <div className="timeline-steps">
            {steps.map((step, index) => (
              <div key={index} className="timeline-step">
                <div className="step-icon">
                  {step.icon}
                </div>
                <div className="step-content">
                  <span className="step-number">{step.number}</span>
                  <h3 className="step-title">{step.title}</h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Background Elements */}
      <div className="journey-glow-1"></div>
      <div className="journey-glow-2"></div>
      <div className="journey-grid"></div>
    </section>
  );
};

export default SeoJourney;