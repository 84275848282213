import React from 'react';
import './SeoMeet.css';
import AnimatedSpaceship from '../animations/AnimatedSpaceship/AnimatedSpaceship';

const SeoOptima = () => {
  return (
    <section className="seo-meet">
      {/* <AnimatedSpaceship /> */}
      <div className="seo-meet-content">
        <div className="seo-meet-text">
          {/* <span className="seo-meet-date">Dec 2024</span> */}
          <h2 className="seo-meet-title">Healm Optima</h2>
          
          <p className="seo-meet-description">
            Healm is an innovative augmented reality (AR) app company, partnered with Google 
            to enhance our AR experiences. As part of our comprehensive business offering, 
            we've developed Healm Optima—an AI-driven offsite SEO solution designed to deliver 
            rapid, impactful results for improving online visibility and search rankings.
          </p>

          <p className="seo-meet-description">
            Healm Optima works by analyzing websites, identifying specific keywords, and 
            generating 400 high-quality backlinks per month for each target keyword. This 
            solution is built to boost businesses' digital presence through advanced SEO 
            strategies, helping them achieve higher search rankings and increased online 
            engagement.
          </p>

          <p className="seo-meet-description">
            By improving domain authority and search engine results, Optima helps businesses 
            rise from pages 3 or 4 to the first page, or even the top of search results, 
            typically within 90 days. This powerful SEO tool works in tandem with Healm's 
            augmented reality technology, offering an integrated approach to business growth.
          </p>

          <p className="seo-meet-description">
            By leveraging both AR and AI-driven SEO, we help businesses adapt to the changing 
            landscape of digital search, especially with the upcoming advancements of Google's 
            Emersive AI. Through this holistic strategy, we empower businesses to enhance their 
            online performance, drive more traffic, and achieve long-term success.
          </p>
        </div>

        <div className="seo-meet-visual">
          <div className="seo-meet-logo">
            <img src='./logo.png' alt="HEALM Logo" />
          </div>
        </div>
      </div>

      <button 
        className="seo-meet-cta"
        onClick={() => {/* Calendly Trigger here */}}
      >
        Book Your Strategy Call
      </button>
    </section>
  );
};

export default SeoOptima;