import React from 'react';
import './SeoImpact.css';
import { User } from 'lucide-react';

const SeoImpact = () => {
  const metrics = [
    {
      value: "65%",
      title: "Personalised Content Optimisation",
      dotCount: 24,
      activeDots: 16
    },
    {
      value: "100%",
      title: "Dynamic Keyword Targeting",
      dotCount: 24,
      activeDots: 24
    },
    {
      value: "30%",
      title: "Predictive Ranking Insights",
      dotCount: 24,
      activeDots: 7
    },
    {
      value: "90%",
      title: "Automated Link Building",
      dotCount: 24,
      activeDots: 22
    }
  ];

  const renderDots = (total, active) => {
    return Array(total).fill().map((_, i) => (
      <div 
        key={i} 
        className={`impact-dot ${i < active ? 'active' : ''}`}
      />
    ));
  };

  return (
    <section className="seo-impact">
      <div className="seo-impact-content">
        <h2 className="seo-impact-title">
          Impact on Visibility and Client Growth
        </h2>
        <p className="seo-impact-subtitle">
          Leveraging AI-driven offsite SEO to exponentially increase brand visibility, 
          drive organic growth, and accelerate client acquisition.
        </p>

        <div className="seo-impact-grid">
          {metrics.map((metric, index) => (
            <div key={index} className="seo-impact-metric">
              <div className="metric-header">
                <span className="metric-value">{metric.value}</span>
                <h3 className="metric-title">{metric.title}</h3>
              </div>
              <div className="metric-visualization">
                <div className="dot-grid">
                  {renderDots(metric.dotCount, metric.activeDots)}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="seo-impact-footer">
          AI-Driven Consistency, Accuracy, and Scalable Growth
        </div>
      </div>

      {/* Visual elements */}
      <div className="impact-sphere"></div>
      <div className="impact-glow-1"></div>
      <div className="impact-glow-2"></div>
      <div className="impact-lines"></div>
    </section>
  );
};

export default SeoImpact;