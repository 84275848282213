import React, { useState, useRef, useEffect } from 'react';
import { Scale, LineChart, BadgeCheck, Users, DollarSign } from 'lucide-react';
import './SeoBenefits.css';

const SeoBenefits = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const scrollRef = useRef(null);

  const benefits = [
    {
      icon: <Scale size={32} />,
      number: "01",
      title: "Content Generation at Scale",
      description: "Healm's AI technology can create content at a scale that would take a human team weeks or even months to complete."
    },
    {
      icon: <LineChart size={32} />,
      number: "02",
      title: "Increased Productivity",
      description: "By leveraging AI, Healm can generate high-quality content in a fraction of the time it would take a human team, leading to significant productivity gains."
    },
    {
      icon: <BadgeCheck size={32} />,
      number: "03",
      title: "Consistent Quality",
      description: "Healm's AI-powered content generation ensures a consistently high level of quality, maintaining brand standards and delivering engaging experiences."
    },
    {
      icon: <Users size={32} />,
      number: "04",
      title: "Personalisation at Scale",
      description: "Healm's AI can personalize content for individual users, creating tailored experiences that resonate with the target audience."
    },
    {
      icon: <DollarSign size={32} />,
      number: "05",
      title: "Cost-Effective Content",
      description: "The efficiency of Healm's AI-generated content can lead to significant cost savings compared to traditional content creation methods."
    }
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        const scrollPosition = scrollRef.current.scrollLeft;
        const cardWidth = scrollRef.current.offsetWidth;
        const newIndex = Math.round(scrollPosition / cardWidth);
        setActiveIndex(newIndex);
      }
    };

    const scrollContainer = scrollRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
      return () => scrollContainer.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const scrollTo = (index) => {
    if (scrollRef.current) {
      const cardWidth = scrollRef.current.offsetWidth;
      scrollRef.current.scrollTo({
        left: cardWidth * index,
        behavior: 'smooth'
      });
      setActiveIndex(index);
    }
  };

  return (
    <section className="seo-benefits">
      <div className="seo-benefits-content">
        <h2 className="seo-benefits-title">
          Healm Optima: The Power of AI-Generated Content
        </h2>

        <div className="seo-benefits-grid" ref={scrollRef}>
          {benefits.map((benefit, index) => (
            <div 
              key={index} 
              className={`seo-benefit-card ${index === activeIndex ? 'active' : ''}`}
              style={{ animationDelay: `${index * 0.1}s` }}
            >
              <div className="seo-benefit-number">{benefit.number}</div>
              <div className="seo-benefit-content">
                <div className="seo-benefit-header">
                  <div className="seo-benefit-icon">
                    <div className="seo-benefit-icon-inner">
                      {benefit.icon}
                    </div>
                  </div>
                </div>
                <h3 className="seo-benefit-title">{benefit.title}</h3>
                <p className="seo-benefit-description">{benefit.description}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="benefits-progress">
          {benefits.map((_, index) => (
            <button
              key={index}
              className={`progress-dot ${index === activeIndex ? 'active' : ''}`}
              onClick={() => scrollTo(index)}
              aria-label={`Go to benefit ${index + 1}`}
            />
          ))}
        </div>
      </div>
      
      {/* Background Elements */}
      <div className="benefits-glow-1"></div>
      <div className="benefits-glow-2"></div>
      <div className="benefits-grid-bg"></div>
    </section>
  );
};

export default SeoBenefits;